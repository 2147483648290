import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ExternalKeyFindingGraph from "../chart/external-key-findings-graph";
import { Button, Modal, notification } from "antd";
import Axios from "axios";
import qs from "qs";
import LS_SERVICE from "../../utils/localStorage";
import {
  EX_KEYFINDINGS_PRINT_SAVE,
  GET_SELECTED_KEYFINDINGS,
} from "../../utils/constant";

class ExternalKeyFinding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference_type: LS_SERVICE.get("reference_type"),
      reference_id: LS_SERVICE.get("call_patient_qms_token_id"),
      patient_id: LS_SERVICE.get("call_patient_id"),
      //
      externalKeyFindings: props.externalKeyFindings ?? {},
      searchQuery: "",
      currentIndex: 0,
      rowsPerPage: 3,
      originalLabels: props.externalKeyFindings?.label || [],
      selectedLabels: [],
      selectedData: [],
      graphHeading: "External Key Findings",
      graphModel: false,
      graphType: "",
      graphTestID: null,
      graphSubTestID: null,
    };
  }

  componentDidMount() {
    this.getSelectedKeyFinding();
  }

  getSelectedKeyFinding = () => {
    const { reference_id, patient_id, reference_type } = this.state;

    const data = {
      reference_type,
      reference_id,
      patient_id,
    };

    Axios.post(GET_SELECTED_KEYFINDINGS, data)
      .then((res) => {
        const response = res.data.result.exDetail;

        if (response) {
          const convertedData = Object.values(response).map((item) => ({
            ...item,
            checked: this.convertToBoolean(item.checked),
            sub_test: item.sub_test.map((subTest) => ({
              ...subTest,
              checked: this.convertToBoolean(subTest.checked),
            })),
          }));

          const dateLabel = convertedData.map((e) => e.sub_test[0].date_label);

          this.setState({
            selectedLabels: dateLabel,
            selectedData: convertedData,
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  convertToBoolean = (checked) => {
    return checked === "true" || checked === true;
  };

  handleGraphModal = () => {
    this.setState({ graphModel: !this.state.graphModel });
  };

  graphModal = (data, label, type) => {
    this.setState({
      graphTestID: type !== "all" ? data.test_id : data.id,
      graphSubTestID: type !== "all" ? data.id : null,
      graphModel: true,
      graphType: type,
      graphHeading: label,
    });
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  filterData = (data) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return data;

    const lowerSearchQuery = searchQuery.toLowerCase();
    return data.filter((item) => {
      if (item.label.toLowerCase().includes(lowerSearchQuery)) return true;

      return item.value.some((subTest) =>
        subTest.label.toLowerCase().includes(lowerSearchQuery)
      );
    });
  };

  handleNext = () => {
    const { currentIndex, rowsPerPage, originalLabels } = this.state;
    const totalRows = originalLabels.length;
    this.setState({
      currentIndex: Math.min(
        currentIndex + rowsPerPage,
        totalRows - rowsPerPage
      ),
    });
  };

  handleBack = () => {
    const { currentIndex, rowsPerPage } = this.state;
    this.setState({
      currentIndex: Math.max(currentIndex - rowsPerPage, 0),
    });
  };

  handleDateCheckboxChange = (event, label, allTestData) => {
    const { checked } = event.target;

    this.setState(
      (prevState) => {
        const newSelectedLabels = checked
          ? [...prevState.selectedLabels, label]
          : prevState.selectedLabels.filter((item) => item !== label);

        const newSelectedData = checked
          ? [
              ...prevState.selectedData,
              ...allTestData.flatMap((test) => {
                if (
                  test.value &&
                  test.value.length > 0 &&
                  test.value.flatMap((subTest) =>
                    subTest.value.filter((subTest0) => subTest0.label === label)
                  ).length > 0
                ) {
                  return {
                    test_name: `${test.label} - ${label}`,
                    test_name_raw: test.label,
                    date: label,
                    checked: true,
                    sub_test: test.value.flatMap((subTest) =>
                      subTest.value
                        .filter((subTest0) => subTest0.label === label)
                        .map((subTest0) => ({
                          checked: true,
                          date_label: subTest0.label,
                          sub_test_label: subTest.label,
                          value: subTest0.value,
                          isNormal: subTest0.range,
                          range: subTest.range,
                        }))
                    ),
                  };
                }
                return [];
              }),
            ]
          : prevState.selectedData.filter((item) => item.test_name !== label);

        return {
          selectedLabels: newSelectedLabels,
          selectedData: newSelectedData,
        };
      },
      () => {
        console.log("Selected Labels: ", this.state.selectedLabels);
        console.log("Selected Data: ", this.state.selectedData);
      }
    );
  };

  handleCheckAll = (test_name, event) => {
    const { selectedData } = this.state;
    const isChecked = event.target.checked;

    if (selectedData && test_name) {
      const updatedSelectedData = selectedData.map((item) => {
        if (item.test_name === test_name) {
          return {
            ...item,
            checked: isChecked,
            sub_test: item.sub_test.map((subTest) => ({
              ...subTest,
              checked: isChecked,
            })),
          };
        }
        return item;
      });

      this.setState({ selectedData: updatedSelectedData });
    }
  };

  handleCheckOnly = (test_name, sub_test_name, event) => {
    const { selectedData } = this.state;
    const isChecked = event.target.checked;

    if (selectedData && test_name) {
      const updatedSelectedData = selectedData.map((item) => {
        if (item.test_name === test_name) {
          const updatedSubTests = item.sub_test.map((subTest) => {
            if (subTest.sub_test_label === sub_test_name) {
              return { ...subTest, checked: isChecked };
            }
            return subTest;
          });

          const allSubTestsChecked = updatedSubTests.every(
            (subTest) => subTest.checked
          );

          return {
            ...item,
            checked: allSubTestsChecked,
            sub_test: updatedSubTests,
          };
        }
        return item;
      });

      this.setState({ selectedData: updatedSelectedData });
    }
  };

  renderSubTestRow = (subTest, dates, majorTest) => {
    return (
      <tr key={subTest.id}>
        <td style={{ paddingTop: "25px" }}>{subTest.label}</td>
        {dates.map((date) => {
          const foundData = subTest.value.find((item) => item.label === date);
          return (
            <td
              className={`text-center ${
                foundData ? this.GET_RANGE_CLASS(foundData.range) : ""
              }`}
              key={date}
              style={{ maxWidth: "114px", width: "114px" }}
            >
              {foundData ? foundData.value : "-"}
            </td>
          );
        })}
        <td className="text-center">{subTest.range}</td>
        <td>
          <button
            type="button"
            className="btn chart-btn button-chart-individual"
            onClick={() =>
              this.graphModal(subTest, majorTest.label, "individual")
            }
          >
            <i className="icon-chart"></i>
          </button>
        </td>
      </tr>
    );
  };

  renderTable = (data) => {
    const { currentIndex, rowsPerPage } = this.state;
    const dates =
      data.label.slice(currentIndex, currentIndex + rowsPerPage) || [];
    const rows = data.value || [];
    const filteredRows = this.filterData(rows);

    return filteredRows.map((majorTest) => (
      <>
        <tr key={majorTest.id}>
          <td style={{ paddingTop: "25px" }} className="text-bold">
            {majorTest.label}
          </td>
          {dates.map((date) => (
            <td key={date}></td>
          ))}
          <td></td>
          <td>
            <button
              type="button"
              className="btn chart-btn button-chart-individual"
              onClick={() => this.graphModal(majorTest, majorTest.label, "all")}
            >
              <i className="icon-chart"></i>
            </button>
          </td>
        </tr>
        {majorTest.value.map((subTest) =>
          this.renderSubTestRow(subTest, dates, majorTest)
        )}
      </>
    ));
  };

  GET_RANGE_CLASS = (status) => {
    if (status === "-") return "";
    let vitalRange = "";
    if (status === "N") vitalRange = "text-success";
    if (status === "P") vitalRange = "text-danger";
    if (status === "A" || status === "L" || status === "H")
      vitalRange = "text-warning";
    return vitalRange;
  };

  submitExKeyFinding = async () => {
    const { selectedData, reference_type, reference_id, patient_id } =
      this.state;
    if (!selectedData) return;

    const PARAMS = {
      reference_type: reference_type,
      reference_id: reference_id,
      patient_id: patient_id,
      detail: qs.stringify(selectedData),
    };

    const printAddResult = await Axios.post(
      EX_KEYFINDINGS_PRINT_SAVE,
      qs.stringify(PARAMS)
    );
    if (printAddResult.data.status) {
      notification.success({
        message: printAddResult.data.message,
        placement: "topRight",
      });
    } else {
      notification.error({
        message: printAddResult.data.message,
        placement: "topRight",
      });
    }

    console.log("Submit Key Finding: ", selectedData);
  };

  render() {
    const {
      externalKeyFindings,
      currentIndex,
      rowsPerPage,
      originalLabels,
      graphModel,
      graphHeading,
      graphType,
      selectedLabels,
      graphTestID,
      graphSubTestID,
      selectedData,
    } = this.state;

    const visibleLabels = originalLabels.slice(
      currentIndex,
      currentIndex + rowsPerPage
    );

    return (
      <>
        <div className="card-body vitals">
          <div className="row">
            <div
              className={`table-responsive ${
                selectedData.length > 0 ? "col-8" : "col-12"
              } `}
              style={{ padding: "0px" }}
            >
              <div className="fixed-table">
                <div className="antInputItem m-3">
                  <input
                    type="text"
                    placeholder="Search by test name..."
                    className="form-control"
                    value={this.state.searchQuery}
                    onChange={this.handleSearchChange}
                  />
                </div>

                {visibleLabels.length > 0 ? (
                  <table className="table">
                    <tbody>
                      <tr>
                        <th width="20%">
                          Parameter
                          {currentIndex > 0 && (
                            <span
                              style={{ position: "absolute", right: "1px" }}
                            >
                              <a>
                                <span
                                  className="float-right chevron chevron-left arrow-left"
                                  onClick={this.handleBack}
                                />
                              </a>
                            </span>
                          )}
                        </th>

                        {visibleLabels.map((v, i) => (
                          <th
                            key={i}
                            className="pl-0 pr-0"
                            style={{ maxWidth: "250px" }}
                          >
                            <div className="row">
                              <div className="col-12 text-center vital-time">
                                <input
                                  type="checkbox"
                                  checked={selectedLabels.includes(v)}
                                  onChange={(e) => {
                                    this.handleDateCheckboxChange(
                                      e,
                                      v,
                                      externalKeyFindings.value
                                    );
                                  }}
                                />
                                <br />
                                {v}
                              </div>
                            </div>
                          </th>
                        ))}

                        <th
                          className="text-center text-bold"
                          style={{ width: "132px" }}
                        >
                          Reference Range
                          {currentIndex + rowsPerPage <
                            originalLabels.length && (
                            <span style={{ position: "absolute", left: "1px" }}>
                              <a>
                                <span
                                  className="float-right chevron chevron-right arrow-right"
                                  onClick={this.handleNext}
                                />
                              </a>
                            </span>
                          )}
                        </th>
                        <th
                          className="text-center text-bold"
                          style={{ width: "50px" }}
                        >
                          Trend Graph
                        </th>
                      </tr>
                      {this.renderTable(externalKeyFindings)}
                    </tbody>
                  </table>
                ) : (
                  <h6 className="text-center">No data found.</h6>
                )}
              </div>
            </div>

            <div
              className="col-4"
              style={
                selectedData.length ? { padding: "0px" } : { display: "none" }
              }
            >
              <div className="fixed-table">
                <>
                  {selectedData.map((item, index) => (
                    <div key={index}>
                      <div
                        className="font-weight-bold text-center row"
                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        <div className="col-1">
                          <input
                            type="checkbox"
                            checked={selectedData[index].checked}
                            onClick={(e) =>
                              this.handleCheckAll(item.test_name, e)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="col-11">
                          <label>{item.test_name}</label>
                        </div>
                      </div>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th width="50%">Parameter</th>
                            <th className="text-center" width="15%">
                              Value
                            </th>
                            <th width="25%">Reference Range</th>
                          </tr>
                          {item.sub_test.map((value, idx) => (
                            <tr key={idx}>
                              <td>
                                <div>
                                  <input
                                    type="checkbox"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    onClick={(e) =>
                                      this.handleCheckOnly(
                                        item.test_name,
                                        value.sub_test_label,
                                        e
                                      )
                                    }
                                    checked={
                                      selectedData[index].sub_test[idx].checked
                                    }
                                    defaultChecked={
                                      selectedData[index].sub_test[idx].checked
                                    }
                                  />
                                  <label>{value.sub_test_label}</label>
                                </div>
                              </td>
                              <td
                                className={`text-center ${
                                  value
                                    ? this.GET_RANGE_CLASS(value.isNormal)
                                    : ""
                                }`}
                              >
                                {value.value}
                              </td>
                              <td>{value.range}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-12 text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.submitExKeyFinding("exKeyFinding")}
                    className="btn btn-sm btn-primary"
                  >
                    ADD DATA TO PRINT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {graphModel && (
          <Modal
            destroyOnClose={true}
            title={graphHeading}
            visible={graphModel}
            onCancel={this.handleGraphModal}
            style={{
              top: "40px",
              maxWidth: graphType === "all" ? "90%" : "600px",
            }}
            className={graphType === "all" ? "all_vital" : "single_vital"}
            width="100%"
            bodyStyle={{ maxHeight: "650px", overflowY: "auto" }}
            footer={null}
          >
            <ExternalKeyFindingGraph
              data={externalKeyFindings}
              graphHeading={graphHeading}
              graphType={graphType}
              graphTestID={graphTestID}
              graphSubTestID={graphSubTestID}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default ExternalKeyFinding;
