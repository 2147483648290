import React, { Component } from "react";
import { Button, Form, Input, DatePicker, notification, Spin } from "antd";
import LS_SERVICE from "../../utils/localStorage";
import { EXTERNAL_KEY_FINDINGS_SAVE } from "../../utils/constant";
import Axios from "axios";

import IconAdd from "../../assets/images/icon-add.png";
import IconDelete from "../../assets/images/delete-icon.png";
import moment from "moment";

class AddExternalKeyFinding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      externalKeyFindings: props.externalKeyFindings ?? [],
      searchTerm: "",
      loading: false,
    };
    this.formRef = React.createRef();
  }

  onFinish = (values) => {
    this.setState({ loading: true });
    const transformedData = Object.keys(values).reduce((acc, testKey) => {
      const testData = values[testKey];

      const transformedTest = testData.map((test) => {
        const componentList = Object.keys(test)
          .filter(
            (key) =>
              key !== "id" &&
              key !== "hospital_id" &&
              key !== "test_id" &&
              key !== "date" &&
              key !== "reference_id" &&
              key !== "reference_type" &&
              key !== "patient_id" &&
              key !== "doctor_id" &&
              test[key] !== undefined &&
              test[key] !== "" &&
              test[key] !== null
          )
          .map((key) => ({
            component_id: key,
            value: test[key],
          }));

        return {
          ...test,
          component_list: componentList,
        };
      });

      acc[testKey] = transformedTest;
      return acc;
    }, {});

    console.log("Transformed Data:", transformedData);
    Axios.post(EXTERNAL_KEY_FINDINGS_SAVE, { tests: transformedData })
      .then((res) => {
        if (res.data.status == true) {
          notification.success({
            message: res.data.message,
            placement: "topRight",
          });
          this.formRef.current.resetFields();
          this.props.handleExKeyFindingModel();
        } else {
          notification.error({
            message: res.data.message,
            placement: "topRight",
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("Error", err);
      });
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });
  };

  filterTestsBySearchTerm = () => {
    const { externalKeyFindings, searchTerm } = this.state;
    if (!searchTerm) {
      return externalKeyFindings;
    }
    return externalKeyFindings.filter(
      (test) =>
        test.disp_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        test.test_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  renderTestRow(test, testIndex) {
    return (
      <Form.List
        name={`test-${testIndex}`}
        initialValue={[{}]}
        onChange={(e) => console.log("Form List Change", e)}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="row mt-1" key={key}>
                <Form.Item name={[name, "id"]} initialValue={null} hidden />
                <Form.Item
                  name={[name, "hospital_id"]}
                  initialValue={LS_SERVICE.get("FACILITY_ID")}
                  hidden
                />
                <Form.Item
                  name={[name, "reference_id"]}
                  initialValue={LS_SERVICE.get("call_patient_qms_token_id")}
                  hidden
                />
                <Form.Item
                  name={[name, "reference_type"]}
                  initialValue={LS_SERVICE.get("reference_type")}
                  hidden
                />
                <Form.Item
                  name={[name, "patient_id"]}
                  initialValue={LS_SERVICE.get("call_patient_id")}
                  hidden
                />
                <Form.Item
                  name={[name, "doctor_id"]}
                  initialValue={LS_SERVICE.get("staff_id")}
                  hidden
                />
                <Form.Item
                  name={[name, "test_id"]}
                  initialValue={test.id}
                  noStyle
                  hidden
                />
                <div className="col-11">
                  <div className="row mt-1">
                    <div className="col-3">
                      <Form.Item
                        name={[name, "date"]}
                        rules={[
                          {
                            required: false,
                            message: `Please select ${test.disp_name} date`,
                          },
                        ]}
                      >
                        <DatePicker
                          className="form-control"
                          disabledDate={(current) =>
                            current && current > moment().endOf("day")
                          }
                        />
                      </Form.Item>
                    </div>

                    {test.test_components.map((component, componentIndex) => (
                      <div className="col-3 antInputItem multi-col customcssvitalform">
                        <Form.Item
                          {...restField}
                          name={[name, component.test_component_id]}
                          label={component.test_component_name}
                          rules={[
                            {
                              required: false,
                              message: `Please enter ${component.test_component_name} value`,
                            },
                          ]}
                        >
                          <div className="multi-input-col-inner">
                            <div className="ant-input-wrapper ant-input-group">
                              <div className="ant-input">
                                {component.result_type === "Alpha Numeric" ? (
                                  <Input
                                    type="text"
                                    placeholder="Enter alphanumeric value"
                                    pattern="^[A-Za-z0-9]*$"
                                    title="Alphanumeric only, no special characters"
                                    onKeyDown={(e) => {
                                      const regex = /^[A-Za-z0-9]$/;
                                      if (
                                        !regex.test(e.key) &&
                                        e.key !== "Backspace" &&
                                        e.key !== "Tab"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^A-Za-z0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                ) : component.result_type === "Numeric" ? (
                                  <Input
                                    type="number"
                                    placeholder="Enter numeric value"
                                    title="Numeric only"
                                    min="0"
                                    step="0.01"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      const decimalPlaces =
                                        component.component_detail
                                          .decimal_places;
                                      const regex = new RegExp(
                                        `^\\d*(\\.\\d{0,${decimalPlaces}})?$`
                                      );
                                      if (!regex.test(value)) {
                                        e.target.value = value.slice(0, -1);
                                      }
                                    }}
                                  />
                                ) : (
                                  <Input
                                    type="text"
                                    placeholder="Enter value"
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^A-Za-z0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <span className="ant-input-group-addon">
                                {component.component_detail.uom}
                              </span>
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-auto mt-2">
                  {key == 0 ? (
                    <a onClick={() => add()} className="nostyle-link">
                      <img
                        src={IconAdd}
                        alt="add"
                        className="ml-2"
                        style={{ width: "25px", height: "auto" }}
                      />
                    </a>
                  ) : null}
                  {key != 0 ? (
                    <a onClick={() => remove(name)} className="nostyle-link">
                      <img
                        src={IconDelete}
                        alt="add"
                        className="ml-3"
                        style={{ width: "25px", height: "auto" }}
                      />
                    </a>
                  ) : null}
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    );
  }

  render() {
    const { searchTerm, loading } = this.state;
    const filteredExternalKeyFindings = this.filterTestsBySearchTerm();

    return (
      <>
        <Spin spinning={loading}>
          <Form
            ref={this.formRef}
            className="emrfrm"
            layout="vertical"
            onFinish={this.onFinish}
          >
            <div
              className={`table-responsive ${"col-12"}`}
              style={{ padding: "0px" }}
            >
              <div className="fixed-table">
                <div className="antInputItem m-3">
                  <div className="form-group">
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder="Search by test name..."
                        value={searchTerm}
                        onChange={this.handleSearchChange}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div>
                  {filteredExternalKeyFindings.map((test, testIndex) => (
                    <>
                      <div className="container-fluid pr-3 pl-3">
                        <span
                          className="text-primary mb-0"
                          style={{ fontSize: "1rem", fontWeight: "500" }}
                        >
                          {test.disp_name + " (" + test.test_code + ")"}
                        </span>
                        {this.renderTestRow(test, test.id)}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                float: "right",
                marginTop: "20px",
                marginBottom: "10px",
                marginRight: "10px",
                marginLeft: "13px",
              }}
            >
              Submit
            </Button>
          </Form>
        </Spin>
      </>
    );
  }
}

export default AddExternalKeyFinding;
