import React from "react";
import { Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
import {
  EX_KEYFINDINGS_PRINT_SAVE,
  GET_SELECTED_KEYFINDINGS,
} from "../../utils/constant";
import Axios from "axios";
import { notification } from "antd";
import qs from "qs";
import LS_SERVICE from "../../utils/localStorage";

class ExternalKeyFindingGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference_type: LS_SERVICE.get("reference_type"),
      reference_id: LS_SERVICE.get("call_patient_qms_token_id"),
      patient_id: LS_SERVICE.get("call_patient_id"),
      //
      chartData: props?.data,
      chartDataMapped: {},
      heading: [],
      selectedGraphData: {}, // Stores selected graph data
    };
  }

  componentDidMount() {
    this.getSelectedKeyFinding();
    this.handleDynamicDataCreation();
  }

  getSelectedKeyFinding = (_) => {
    const { reference_id, patient_id, reference_type } = this.state;
    let data = {
      reference_type: reference_type,
      reference_id: reference_id,
      patient_id: patient_id,
    };
    Axios.post(GET_SELECTED_KEYFINDINGS, data)
      .then((res) => {
        let response = res.data.result.exGraph;
        if (response !== null) {
          this.setState({
            selectedGraphData: response,
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  GET_RANGE_CLASS = (status) => {
    if (status === "-") return "";
    let vitalRange = "";
    // Normal range
    if (status === "N") vitalRange = "#28a745";

    // Abnormal range
    if (status === "A" || status === "P") vitalRange = "#eb2d2e";

    // Critical range
    if (status === "L" || status === "H") vitalRange = "#ffc107";

    return vitalRange;
  };

  handleDynamicDataCreation() {
    const data = this.state.chartData?.value;
    let chartDataMapped = {};
    let heading = [];

    if (Array.isArray(data)) {
      data.forEach((mainData) => {
        const { label: testLabel, value: testValues, id: testID } = mainData;

        if (Array.isArray(testValues)) {
          testValues.forEach((test) => {
            const { label: subtestLabel, id: subtestID, value } = test;
            const valuesArray = [];
            const colorArray = [];
            const dateArray = [];

            value.forEach((entry) => {
              valuesArray.push(entry.value);
              colorArray.push(this.GET_RANGE_CLASS(entry.range));
              dateArray.push(entry.label);
            });

            chartDataMapped[subtestID] = {
              testLabel,
              testID,
              subtestLabel,
              data: {
                labels: dateArray.reverse(),
                datasets: [
                  {
                    label: subtestLabel,
                    backgroundColor: "rgb(102, 102, 102)",
                    borderColor: "rgb(102, 102, 102)",
                    pointBackgroundColor: colorArray.reverse(),
                    pointBorderColor: colorArray.reverse(),
                    borderWidth: 1,
                    fill: false,
                    spanGaps: true,
                    data: valuesArray.reverse(),
                  },
                ],
              },
              options: {
                responsive: true,
                animation: { duration: 0 },
                plugins: {
                  legend: { display: false },
                  datalabels: { font: { size: 0 } },
                },
                title: { display: true, text: subtestLabel, fontSize: 15 },
                elements: { line: { tension: 0 } },
                legend: {
                  position: "bottom",
                  display: false,
                  labels: { fontSize: 11, boxWidth: 6 },
                },
                scales: {
                  x: {
                    display: true,
                    ticks: { font: { size: 11 } },
                  },
                  y: {
                    position: "left",
                    display: true,
                    suggestedMin: Math.min(...valuesArray),
                    suggestedMax: Math.max(...valuesArray),
                    ticks: { font: { size: 11 }, precision: 1, stepSize: 1 },
                  },
                },
              },
            };

            heading.push(subtestID);
          });
        }
      });
    }

    this.setState({ chartDataMapped, heading });
  }

  handleCheckboxChange = (subtestID) => {
    this.setState(
      (prevState) => {
        const { selectedGraphData, chartDataMapped } = prevState;

        const selectedGraph = chartDataMapped[subtestID];
        const testTitle = selectedGraph?.testLabel;
        const testID = selectedGraph?.testID;
        const subTestTitle = selectedGraph?.subtestLabel;
        const data = selectedGraph?.data;
        const options = selectedGraph?.options;

        if (selectedGraphData[testID + "_" + subtestID]) {
          const newSelectedGraphData = { ...selectedGraphData };
          delete newSelectedGraphData[testID + "_" + subtestID];
          return {
            selectedGraphData: newSelectedGraphData,
          };
        } else {
          const newSelectedGraphData = {
            ...selectedGraphData,
            [testID + "_" + subtestID]: {
              test_title: testTitle,
              test_id: testID,
              sub_test_id: subtestID,
              sub_test_title: subTestTitle,
              data: data,
              options: options,
            },
          };
          return {
            selectedGraphData: newSelectedGraphData,
          };
        }
      },
      async () => {
        const { selectedGraphData, reference_type, reference_id, patient_id } =
          this.state;

        const PARAMS = {
          reference_type: reference_type,
          reference_id: reference_id,
          patient_id: patient_id,
          selectedGraph: qs.stringify(selectedGraphData),
        };

        const printAddResult = await Axios.post(
          EX_KEYFINDINGS_PRINT_SAVE,
          qs.stringify(PARAMS)
        );
        if (printAddResult.data.status) {
          notification.success({
            message: printAddResult.data.message,
            placement: "topRight",
          });
        } else {
          notification.error({
            message: printAddResult.data.message,
            placement: "topRight",
          });
        }

        console.log("Selected Graph Data: ", this.state.selectedGraphData);
      }
    );
  };

  render() {
    const { heading, chartDataMapped, selectedGraphData } = this.state;
    const { graphType, graphTestID, graphSubTestID } = this.props;

    let filteredData = heading;

    if (graphType === "all") {
      filteredData = heading.filter(
        (subtest) => chartDataMapped[subtest]?.testID === graphTestID
      );
    }

    if (graphType === "individual" && graphSubTestID) {
      filteredData = [graphSubTestID];
    }

    return (
      <div
        className={`chartCanvasWrapper ${
          this.props.graphType === "individual"
            ? "chartCanvasWrapperSmall"
            : "w-100"
        }`}
      >
        {filteredData.length > 0 ? (
          filteredData.map((subtest, index) => {
            const chartData = chartDataMapped[subtest]?.data;
            const chartOptions = chartDataMapped[subtest]?.options;

            if (!chartData || !chartData.labels || !chartData.datasets.length) {
              return <div key={index}>No data available for {subtest}</div>;
            }

            return (
              <div className="chartCanvas" key={index}>
                <div className="row pl-2 pr-2">
                  <div className="col-6">
                    <h6
                      style={{
                        padding: "0.5rem 0rem",
                        marginBottom: "0",
                        fontWeight: "700",
                      }}
                    >
                      {chartDataMapped[subtest]?.subtestLabel}
                    </h6>
                  </div>
                  <div className="col-6">
                    <div
                      className="form-check"
                      style={{ float: "right", textAlign: "right" }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${subtest}`}
                        checked={
                          selectedGraphData[
                            chartDataMapped[subtest]?.testID + "_" + subtest
                          ] !== undefined
                        }
                        onChange={() => this.handleCheckboxChange(subtest)}
                      />
                    </div>
                  </div>
                </div>

                <Line data={chartData} options={chartOptions} />
              </div>
            );
          })
        ) : (
          <div>No Chart Data</div>
        )}
      </div>
    );
  }
}

export default ExternalKeyFindingGraph;
